// @flow

import React from 'react';
import iconEquotip from '../img/appIcon/appIconEquotipLive.svg';
import iconSchmidt from '../img/appIcon/appIconOriginalSchmidtLive.svg';
import iconFDL from '../img/appIcon/appIconProceqFlawDetectorLive.svg';
import iconGPR from '../img/appIcon/appIconProceqGPRLive.svg';
import iconGPRInsights from '../img/appIcon/appIconProceqGPRInsights.svg';
import iconGPRMounted from '../img/appIcon/appIconProceqGPRMounted.svg';
import iconGPRSolo from '../img/appIcon/appIconProceqGPRLiveSolo.svg';
import iconGPRSoil from '../img/appIcon/appIconProceqGSLive.svg';
import iconPunditImpact from '../img/appIcon/appIconPunditImpact.svg';
import iconPundit from '../img/appIcon/appIconPunditLiveArray.svg';
import iconInspect from '../img/appIcon/appIconScreeningEagleInspect.svg';
import iconProfometer from '../img/appIcon/appIconProfometer.svg';
import iconGLM from '../img/appIcon/appIconZehtnerGlossmeterLive.svg';
import iconPS from '../img/appIcon/appIconPaperSchmidt.svg';
import styles from './AppIcons.styl';

const appIconMap: { [key: string]: string } = {
	EQUOTIP: iconEquotip,
	SCHMIDT: iconSchmidt,
	FDL: iconFDL,
	GPR: iconGPR,
	GPR_INSIGHTS: iconGPRInsights,
	GPR_SOIL: iconGPRSoil,
	GPR_MOUNTED: iconGPRMounted,
	GPR_SOLO: iconGPRSolo,
	PIT_IE: iconPunditImpact,
	PUNDIT: iconPundit,
	GLM: iconGLM,
	INSPECT: iconInspect,
	PROFOMETER: iconProfometer,
	PAPERSCHMIDT: iconPS,
};

const AppIcon = (props: { product: string, size?: number }) => {
	const { product, size = 40 } = props;
	const icon = appIconMap[product.toUpperCase()];
	if (icon) {
		return (
			<img
				className={styles.app_icon}
				src={icon}
				alt={product}
				style={{
					width: size,
					height: size,
				}}
			/>
		);
	}
	return product;
};

export const AppsTray = (props: { products: Array<string>, size?: number }) => {
	const { products, size } = props;
	return (
		<div className={styles.app_tray}>
			{products.map(product => (
				<AppIcon key={product} product={product} size={size} />
			))}
		</div>
	);
};

export default AppIcon;

// @flow
import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { Form, Input, Checkbox, Tooltip, Icon, Button } from 'antd';
import type { FormProps } from 'antd/lib/form';
import URL from 'apis/urls';
import { connect } from 'react-redux';
import { compose, withState, lifecycle, pure } from 'recompose';
import type { InjectIntlProvidedProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select from 'components/shared/SmarterFormItems/Select';
import DatePicker from 'components/shared/SmarterFormItems/DatePicker';
import styles from 'components/shared/SmarterTable.styl';
import { request } from 'actions/ajax';
import { getTextValue } from 'utils/registryHelper';
import { hasOneOfRoleLevel, hasRoleLevel, isBUOrHigher, ROLES } from 'utils/rolesHelper';
import StyledSelect from 'components/shared/SmarterFormItems/StyledSelect';
import { emailRegex } from '../users/AddEditUser';
import { ContractType, validateSN, productComparator, UserTypeOptions, getName, getValue } from './contractConsts';
import licenseStyles from './InspectContracts.styl';
import type { SerialLicenseNumberSuggestion } from './contractConsts';
import { isProductOrTierDisabled } from '../../utils/productUtils';

const GPR_SERIAL_FORMAT = /^PM08-.{3}-.{4}$/;
const GM8000_SERIAL_FORMAT = /^GM80-.{3}-.{4}$/;
const GPR_PALM_SERIAL_FORMAT = /^GP88-.{3}-.{4}$/;
const GPR_GP8100_SERIAL_FORMAT = /^GP81-.{3}-.{4}$/;
const GPR_SOIL_SERIAL_FORMAT = /^GS80-.{3}-.{4}$/;
const GS9000_SERIAL_FORMAT = /^GS90-.{3}-.{4}$/;
const PUNDIT_SERIAL_FORMAT = /^PE05-.{3}-.{4}$/;
const PUNDIT_PD8050_SERIAL_FORMAT = /^PD80-.{3}-.{4}$/;
const FDL_SERIAL_FORMAT = /^UT80-.{3}-.{4}$/;
const PUNDIT_VISION_SERIAL_FORMAT = /^PR-.{4}-.{4}-.{4}$/;
const GPR_SLICE_SERIAL_FORMAT = /^\d{4,5}$/;
const GPRSIM_SERIAL_FORMAT = /^\d{4,5}$/;
const MA8000_SERIAL_FORMAT = /^MA80-.{3}-.{4}$/;

const serialNoSuggestionMap = {
	GPR: 'PM08-XXX-XXXX',
	GM8000: 'GM80-XXX-XXXX',
	GPR_PALM: 'GP88-XXX-XXXX',
	GPR_GP8100: 'GP81-XXX-XXXX',
	GPR_SOIL: 'GS80-XXX-XXXX',
	GS9000: 'GS90-XXX-XXXX',
	PUNDIT: 'PE05-XXX-XXXX',
	PUNDIT_PD8050: 'PD80-XXX-XXXX',
	FDL: 'UT80-XXX-XXXX',
	PUNDIT_VISION: 'PR-XXXX-XXXX-XXXX',
	GPR_SLICE: '1234 or 12345',
	GPRSIM: '1234 or 12345',
	MA8000: 'MA80-XXX-XXXX',
};

export const AddEditContract = pure(
	({
		loading,
		form,
		contract,
		changeContract,
		isAdmin,
		isPO,
		isPOOrHigher,
		buOrHigher,
		addContract,
		editContract,
		products,
		prodOptions,
		tierOptions,
		setCurrProd,
		tiers,
		contractModified,
		expirationModified,
		contractType,
		setUserType,
		intl: { formatMessage },
		gprLicense,
		serialLicenseNumberSuggestions,
	}: {
		loading: boolean,
		form: FormProps,
		contract?: Contract,
		changeContract: Function,
		isAdmin: boolean,
		isPO: boolean,
		isPOOrHigher: boolean,
		buOrHigher: boolean,
		addContract: Function,
		editContract: Function,
		products: Product[],
		prodOptions: SelectOpts[],
		tierOptions: SelectOpts[],
		setCurrProd: Function,
		tiers: Object[],
		contractModified: boolean,
		expirationModified: boolean,
		contractType: string,
		setUserType: Function,
		gprLicense: GPRLicense,
		serialLicenseNumberSuggestions?: SerialLicenseNumberSuggestion,
	} & InjectIntlProvidedProps) => {
		const isCreateMode = contract === undefined;

		const onSubmit = e => {
			e.preventDefault();
			form.validateFields((err, values) => {
				if (err) {
					return;
				}

				if (values.expirationDate) {
					values.expirationDate = values.expirationDate.format('YYYY-MM-DD');
				}

				if (values.serialNo) {
					values.serialNo = values.serialNo.toUpperCase();
				}

				if (values.gprLicenseId && values.gprLicensePassword) {
					values.networkLicenses = [
						{
							id: gprLicense.id,
							licenseKey: values.gprLicenseId,
							licensePassword: values.gprLicensePassword,
						},
					];
				}

				if (contractType !== ContractType.MaintenanceServices) {
					const prodPatternMap = {
						GPR: GPR_SERIAL_FORMAT,
						GM8000: GM8000_SERIAL_FORMAT,
						GPR_PALM: GPR_PALM_SERIAL_FORMAT,
						GPR_GP8100: GPR_GP8100_SERIAL_FORMAT,
						GPR_SOIL: GPR_SOIL_SERIAL_FORMAT,
						GS_9000: GS9000_SERIAL_FORMAT,
						PUNDIT: PUNDIT_SERIAL_FORMAT,
						PUNDIT_PD8050: PUNDIT_PD8050_SERIAL_FORMAT,
						FDL: FDL_SERIAL_FORMAT,
						PUNDIT_VISION: PUNDIT_VISION_SERIAL_FORMAT,
						GPR_SLICE: GPR_SLICE_SERIAL_FORMAT,
						GPRSIM: GPRSIM_SERIAL_FORMAT,
						MA8000: MA8000_SERIAL_FORMAT,
					};
					const prodErrorMap = {
						GPR: 'App.GPRContractsSerialMismatchError',
						GM8000: 'App.GM8000ContractsSerialMismatchError',
						GPR_PALM: 'App.GPRPalmContractsSerialMismatchError',
						GPR_GP8100: 'App.GP8100ContractsSerialMismatchError',
						GPR_SOIL: 'App.GPRSoilContractsSerialMismatchError',
						GS9000: 'App.GS9000ContractsSerialMismatchError',
						PUNDIT: 'App.PunditContractsSerialMismatchError',
						PUNDIT_PD8050: 'App.PunditPD8050ContractsSerialMismatchError',
						FDL: 'App.FDLContractsSerialMismatchError',
						PUNDIT_VISION: 'App.PunditVisionContractsSerialMismatchError',
						GPR_SLICE: 'App.GPRSliceContractsSerialMismatchError',
						GPRSIM: 'App.GPRSliceContractsSerialMismatchError',
						MA8000: 'App.MA8000ContractsSerialMismatchError',
					};
					const errors = [];
					if (values.serialNo && values.serialNo.match(prodPatternMap[values.product]) === null) {
						errors.push({
							field: 'serialNo',
							value: values.serialNo,
							error: prodErrorMap[values.product],
						});
					}
					if (values.mASerialNo && values.mASerialNo.match(prodPatternMap.MA8000) === null) {
						errors.push({ field: 'mASerialNo', value: values.mASerialNo, error: prodErrorMap.MA8000 });
					}
					if (errors.length > 0 && validateSN(contract, isAdmin)) {
						errors.forEach(error => {
							form.setFields({
								[error.field]: {
									value: error.value,
									errors: [new Error(formatMessage({ id: error.error }))],
								},
							});
						});
						return;
					}
				}

				if (values.hardwareID) {
					values.hardwareID = values.hardwareID.toUpperCase();
				}

				(contract ? editContract : addContract)(values);
			});
		};

		const onProductChange = params => {
			setCurrProd(params);
			if (serialLicenseNumberSuggestions) {
				if (serialLicenseNumberSuggestions[params]) {
					form.setFieldsValue({ serialNo: serialLicenseNumberSuggestions[params].probeSN });
				} else {
					form.setFieldsValue({ serialNo: '' });
				}
			}
			form.setFieldsValue({ tier: '' });
		};

		const onUserTypeChange = params => {
			setUserType(params);
		};

		const onDateChange = v => {
			form.setFieldsValue({ expirationDate: v });
		};

		const isProductGprSoil = form.getFieldValue('product') === 'GPR_SOIL';
		const isGS9000Product = form.getFieldValue('product') === 'GS9000';

		const isGPRProduct =
			form.getFieldValue('product') === 'GPR_SLICE' || form.getFieldValue('product') === 'GPRSIM';

		const showGPRLicenseFields = !isCreateMode && contractType === ContractType.Software && isGPRProduct;

		const nonDisabledProdOptions = prodOptions.filter(prodOption => prodOption.tierEnabled);

		const isWifiDeviceContract = contractType === ContractType.Device;

		const tierDisabled =
			!isCreateMode && !buOrHigher
				? isProductOrTierDisabled(tierOptions, form.getFieldValue('tier'), false)
				: false;

		const showExpirationDate =
			tiers !== undefined &&
			tiers.filter(t => t.tier === form.getFieldValue('tier'))[0] !== undefined &&
			tiers.filter(t => t.tier === form.getFieldValue('tier'))[0].allowExpiration === 1;

		return (
			<Form onSubmit={onSubmit}>
				<Form.Item label={<FormattedMessage id="App.UserType" />} className={styles.half_field}>
					{form.getFieldDecorator('isInternal', {
						rules: [{ required: true, message: formatMessage({ id: 'userType is required' }) }],
					})(<Select selectOpt={UserTypeOptions} onChange={onUserTypeChange} disabled={tierDisabled} />)}
				</Form.Item>
				<Form.Item label={<FormattedMessage id="Proceq.FormEmailGroupID" />} className={styles.half_field}>
					{form.getFieldDecorator('contactEmail', {
						rules: [
							{
								required: true,
								pattern: emailRegex,
								message: formatMessage({ id: 'Proceq.ErrorWrongUsername' }),
							},
						],
					})(<Input disabled={tierDisabled} />)}
				</Form.Item>
				<Form.Item
					label={<FormattedMessage id="Product" />}
					className={showExpirationDate ? styles.one_third_field : styles.half_field}
				>
					{products !== undefined &&
						form.getFieldDecorator('product', {
							rules: [{ required: true }],
						})(
							<Select
								selectOpt={nonDisabledProdOptions}
								onChange={onProductChange}
								disabled={tierDisabled}
							/>
						)}
				</Form.Item>
				<Form.Item
					label={<FormattedMessage id="App.ProductType" />}
					className={showExpirationDate ? styles.one_third_field : styles.half_field}
				>
					{form.getFieldDecorator('tier', {
						rules: [{ required: true }],
					})(
						<StyledSelect
							selectOpt={tierOptions}
							dropdownMatchSelectWidth={false}
							disabled={tierDisabled}
						/>
					)}
				</Form.Item>
				{showExpirationDate && (
					<Form.Item
						label={<FormattedMessage id="Proceq.FormExpirationDate" />}
						className={styles.one_third_field}
					>
						{form.getFieldDecorator('expirationDate', {
							rules: [{ required: true }],
						})(
							<DatePicker
								onChange={onDateChange}
								showRemainingDays
								showRemainingText="TextRemainingXDays"
								disabled={tierDisabled}
							/>
						)}
					</Form.Item>
				)}
				<div>
					{tiers !== undefined &&
					(contractType === undefined || isWifiDeviceContract) /* contract is of type device */ &&
						tiers.filter(t => t.tier === form.getFieldValue('tier'))[0] !== undefined &&
						tiers.filter(t => t.tier === form.getFieldValue('tier'))[0].offerEagleCare === 1 && (
							<Form.Item className={styles.field}>
								{form.getFieldDecorator('isCareRedeemed', {
									valuePropName: 'checked',
									initialValue: false,
								})(
									<Checkbox>
										<FormattedMessage id="App.IsRedeemed" />
										&nbsp;
										<Tooltip title={<FormattedMessage id="App.IsRedeemedToolTip" />}>
											<Icon type="info-circle" />
										</Tooltip>
									</Checkbox>
								)}
							</Form.Item>
						)}
				</div>
				<div>
					{contract && isAdmin && (
						<Form.Item label={<FormattedMessage id="App.ContractsId" />} className={styles.field}>
							<span>{contract.id}</span>
						</Form.Item>
					)}
				</div>
				{showGPRLicenseFields && (
					<div>
						<div className={licenseStyles.gprLicenseFieldLabel}>
							{contract?.product === 'GPR_SLICE' ? (
								<FormattedMessage id="App.GPRSliceLicenseLabel" />
							) : (
								<FormattedMessage id="App.GPRSIMLicenseLabel" />
							)}
						</div>
						<div className={licenseStyles.gprLicenseWrapper}>
							<Form.Item
								required={false}
								label={<FormattedMessage id="App.Contract.LicenseKey" />}
								className={licenseStyles.gprLicenseFields}
							>
								{form.getFieldDecorator('gprLicenseId', {
									initialValue: gprLicense.LicenseID,
								})(<Input disabled={!isPOOrHigher || !isGPRProduct} />)}
							</Form.Item>
							<Form.Item
								required={false}
								label={<FormattedMessage id="App.Contract.LicensePassword" />}
								className={licenseStyles.gprLicenseFields}
							>
								{form.getFieldDecorator('gprLicensePassword', {
									initialValue: gprLicense.LicensePassword,
								})(<Input disabled={!isPOOrHigher || !isGPRProduct} />)}
							</Form.Item>
						</div>
					</div>
				)}
				{(isWifiDeviceContract ||
					(contractType === ContractType.MaintenanceServices &&
						contract &&
						contract.probeSN !== undefined &&
						contract.probeSN !== '')) && (
					<div>
						<Form.Item
							label={
								isProductGprSoil ? (
									'GS8000 S/N'
								) : isGS9000Product ? (
									'GS9000 S/N'
								) : (
									<FormattedMessage id="App.DeviceSN" />
								)
							}
							className={
								isProductGprSoil || isGS9000Product || isWifiDeviceContract
									? styles.half_field
									: styles.field
							}
						>
							{form.getFieldDecorator('serialNo', {
								rules: [{ required: !contract }],
							})(
								<Input
									disabled={
										contractType === ContractType.MaintenanceServices ? !isCreateMode : tierDisabled
									}
									placeholder={serialNoSuggestionMap[form.getFieldValue('product')]}
								/>
							)}
						</Form.Item>
						{isWifiDeviceContract && !isCreateMode && (
							<Form.Item label={'Wi-Fi MAC Address'} className={styles.half_field}>
								{form.getFieldDecorator('hardwareID', {
									rules: [{ required: false }],
								})(<Input disabled={tierDisabled || !buOrHigher} />)}
							</Form.Item>
						)}
						{(isProductGprSoil || isGS9000Product) && (
							<Form.Item label={'MA8000 S/N'} className={styles.half_field}>
								{form.getFieldDecorator('mASerialNo', {
									rules: [{ required: false }],
								})(<Input disabled={tierDisabled} placeholder={serialNoSuggestionMap.MA8000} />)}
							</Form.Item>
						)}
					</div>
				)}

				{contractType === ContractType.Software && (
					<div>
						<Form.Item label={<FormattedMessage id="App.SerialLicenseNo" />} className={styles.field}>
							{form.getFieldDecorator('serialNo', {
								rules: [
									{
										required: isGPRProduct,
										message: formatMessage({ id: 'Proceq.ErrorWrongSerialLicenseNumber' }),
									},
								],
							})(
								<Input
									placeholder={serialNoSuggestionMap[form.getFieldValue('product')]}
									disabled={(!isCreateMode && !isPO) || tierDisabled}
								/>
							)}
						</Form.Item>
					</div>
				)}

				<div>
					<div>
						<Form.Item
							label={<FormattedMessage id="APP.Contract.InvoiceNo" />}
							className={styles.one_third_field}
						>
							{form.getFieldDecorator('invoiceNo', {
								rules: [
									{
										required: true,
										message: formatMessage({ id: 'App.InvoiceNoRequired' }),
									},
								],
							})(<Input disabled={tierDisabled} />)}
						</Form.Item>

						<Form.Item
							label={<FormattedMessage id="APP.Contract.InvoiceNo2" />}
							className={styles.one_third_field}
						>
							{form.getFieldDecorator('invoiceNo2', {
								rules: [{ required: false }],
							})(
								<Input
									disabled={
										!(
											contract &&
											(form.getFieldValue('invoiceNo2') !== '' ||
												(contract !== undefined && contract.invoiceNo2 !== '') ||
												(expirationModified && form.getFieldValue('invoiceNo') !== ''))
										) || tierDisabled
									}
								/>
							)}
						</Form.Item>
						<Form.Item
							label={<FormattedMessage id="APP.Contract.InvoiceNo3" />}
							className={styles.one_third_field}
						>
							{form.getFieldDecorator('invoiceNo3', {
								rules: [{ required: false }],
							})(
								<Input
									disabled={
										!(
											contract &&
											(form.getFieldValue('invoiceNo3') !== '' ||
												(contract !== undefined && contract.invoiceNo3 !== '') ||
												(expirationModified && form.getFieldValue('invoiceNo2') !== ''))
										) || tierDisabled
									}
								/>
							)}
						</Form.Item>
					</div>

					<div>
						<Form.Item
							label={<FormattedMessage id="APP.Contract.InvoiceNo4" />}
							className={styles.one_third_field}
						>
							{form.getFieldDecorator('invoiceNo4', {
								rules: [{ required: false }],
							})(
								<Input
									disabled={
										!(
											contract &&
											(form.getFieldValue('invoiceNo4') !== '' ||
												(contract !== undefined && contract.invoiceNo4 !== '') ||
												(expirationModified && form.getFieldValue('invoiceNo3') !== ''))
										) || tierDisabled
									}
								/>
							)}
						</Form.Item>
						<Form.Item
							label={<FormattedMessage id="APP.Contract.InvoiceNo5" />}
							className={styles.one_third_field}
						>
							{form.getFieldDecorator('invoiceNo5', {
								rules: [{ required: false }],
							})(
								<Input
									disabled={
										!(
											contract &&
											(form.getFieldValue('invoiceNo5') !== '' ||
												(contract !== undefined && contract.invoiceNo5 !== '') ||
												(expirationModified && form.getFieldValue('invoiceNo4') !== ''))
										) || tierDisabled
									}
								/>
							)}
						</Form.Item>
						<Form.Item
							label={<FormattedMessage id="APP.Contract.InvoiceNo6" />}
							className={styles.one_third_field}
						>
							{form.getFieldDecorator('invoiceNo6', {
								rules: [{ required: false }],
							})(
								<Input
									disabled={
										!(
											contract &&
											(form.getFieldValue('invoiceNo6') !== '' ||
												(contract !== undefined && contract.invoiceNo6 !== '') ||
												(expirationModified && form.getFieldValue('invoiceNo5') !== ''))
										) || tierDisabled
									}
								/>
							)}
						</Form.Item>
					</div>
				</div>

				<div>
					<Form.Item label={<FormattedMessage id="BP (customer SAP number)" />} className={styles.half_field}>
						{form.getFieldDecorator('bp', {
							rules: [{ required: false }],
						})(<Input disabled={tierDisabled} />)}
					</Form.Item>
					<Form.Item label={<FormattedMessage id="Remarks" />} className={styles.half_field}>
						{form.getFieldDecorator('remarks', {
							rules: [{ required: false }],
						})(<Input disabled={tierDisabled} />)}
					</Form.Item>
				</div>
				<div>
					<Form.Item
						label={<FormattedMessage id="Proceq.FormAccountTypeBU" />}
						className={styles.one_third_field}
					>
						{form.getFieldDecorator('businessUnit', {
							rules: [{ required: true }],
						})(<Select globalRegister="buunits" disabled={tierDisabled} />)}
					</Form.Item>
					<Form.Item label={<FormattedMessage id="Proceq.FormLanguage" />} className={styles.one_third_field}>
						{form.getFieldDecorator('language', {
							rules: [{ required: true }],
						})(<Select globalRegister="languagescontacttds" disabled={tierDisabled} />)}
					</Form.Item>
					{contractType !== ContractType.Software && (
						<Form.Item label={<FormattedMessage id="Country Sold To" />} className={styles.one_third_field}>
							{form.getFieldDecorator('countrySoldTo', {
								rules: [{ required: !contract }],
							})(<Select globalRegister="countries" disabled={tierDisabled} />)}
						</Form.Item>
					)}
				</div>
				<Form.Item className={styles.submit}>
					<Button type="default" onClick={() => changeContract(null)}>
						<FormattedMessage id="Proceq.LogbookClose" />
					</Button>
					&nbsp;
					{contractModified && (
						<Button type="primary" htmlType="submit" loading={loading}>
							<FormattedMessage id="Proceq.ButtonSubmit" />
						</Button>
					)}
					{!contractModified && (
						<Button disabled type="primary" htmlType="submit" loading={loading}>
							<FormattedMessage id="Proceq.ButtonSubmit" />
						</Button>
					)}
				</Form.Item>
			</Form>
		);
	}
);

export default compose(
	Form.create({
		mapPropsToFields: props => {
			const fields = {};
			const fieldKeys = [
				'isInternal',
				'contactEmail',
				'product',
				'licenseIds',
				'licensePasswords',
				'tier',
				'hardwareID',
				'probeSN',
				'mASN',
				'expirationDate',
				'isCareRedeemed',
				'businessUnit',
				'language',
				'countrySoldTo',
				'bp',
				'remarks',
				'invoiceNo',
				'invoiceNo2',
				'invoiceNo3',
				'invoiceNo4',
				'invoiceNo5',
				'invoiceNo6',
			];
			fieldKeys.forEach(k => {
				let value = get(props, ['contract', k]);
				if (k === 'expirationDate') {
					value = value ? moment(value) : null;
				}
				if (k === 'probeSN') {
					k = 'serialNo';
				}
				if (k === 'mASN') {
					k = 'mASerialNo';
				}
				if (k === 'isCareRedeemed') {
					value = value === 1;
				} else if (k === 'isInternal') {
					value = getName(UserTypeOptions, Number(value));
				}
				fields[k] = Form.createFormField({ value });
			});
			return fields;
		},
	}),
	withState('loading', 'setLoading', false),
	withState('prodOptions', 'setProdOptions', []),
	withState('tiers', 'setTiers', []),
	withState('tierOptions', 'setTierOptions', []),
	withState('userType', 'setUserType', ''),
	withState('currProd', 'setCurrProd', ''),
	withState('prevContract', 'setPrevContract', ''),
	withState('contractModified', 'setContractModified', false),
	withState('expirationModified', 'setExpirationModified', false),
	withState('gprLicense', 'setGprLicense', { id: '', LicenseID: '', LicensePassword: '' }),
	withState('serialLicenseNumberSuggestions', 'setSerialLicenseNumberSuggestions', []),
	connect(
		state => ({
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
			isPO: hasRoleLevel(state.session.scopes, ROLES.PO),
			isPOOrHigher: hasOneOfRoleLevel(state.session.scopes, [ROLES.SUPER, ROLES.ADMIN, ROLES.PO]),
			buOrHigher: isBUOrHigher(state.session.scopes),
		}),
		(dispatch: Function, props) => ({
			addContract: params => {
				if (Object.prototype.hasOwnProperty.call(params, 'isCareRedeemed')) {
					params.isCareRedeemed = params.isCareRedeemed === true ? 1 : 0;
				} else {
					params.isCareRedeemed = 2;
				}
				if (Object.prototype.hasOwnProperty.call(params, 'isInternal')) {
					params.isInternal = Number(params.isInternal);
				}

				dispatch(
					request({
						method: 'POST',
						url: URL.CREATE_WIFI_CONTRACT,
						setLoading: props.setLoading,
						params: {
							...params,
							type: props.contractType,
							shouldVerify: false,
						},
					})
				).then(response => {
					if (response.status === 'success') {
						props.changeContract(null);
						props.loadContracts();
					}
				});
			},
			loadGPRSliceLicenseById: contractId => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_LICENSES_BY_CONTRACT_ID.replace('{key}', contractId),
						setLoading: props.setLoading,
						params: {},
					})
				).then(response => {
					if (response.length > 0 && Object.prototype.hasOwnProperty.call(response[0], 'key')) {
						props.setGprLicense({ id: response[0].id, ...JSON.parse(response[0].key) });
					}
				});
			},
			editContract: params => {
				if (Object.prototype.hasOwnProperty.call(params, 'isCareRedeemed')) {
					params.isCareRedeemed = params.isCareRedeemed === true ? 1 : 0;
				} else {
					params.isCareRedeemed = 2;
				}
				if (Object.prototype.hasOwnProperty.call(params, 'isInternal')) {
					if (isNaN(Number(params.isInternal))) {
						params.isInternal = getValue(UserTypeOptions, params.isInternal);
					} else {
						params.isInternal = Number(params.isInternal);
					}
				}
				dispatch(
					request({
						method: 'PUT',
						url: URL.UPDATE_WIFI_CONTRACT.replace('{key}', props.contract.id),
						setLoading: props.setLoading,
						params: {
							...params,
							type: props.contractType,
							shouldVerify: false,
						},
					})
				).then(response => {
					if (response === '') {
						props.changeContract(null);
						props.loadContracts();
					}
				});
			},
			loadAvailableProducts: () => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_PRODUCT_AVAILABLE,
						setLoading: props.setLoading,
						params: {
							contractType: parseInt(props.contractType, 10),
						},
					})
				).then(data => {
					const options = [];
					const saasProds = getTextValue(['saasProducts']).map(({ value }) => value.toUpperCase());
					if (data !== undefined && data !== null) {
						data.map(prod => {
							if (
								(props.contractType === ContractType.MaintenanceServices ||
									prod.connectionType === props.prodConnectionType) &&
								!saasProds.includes(prod.code.toUpperCase())
							) {
								const obj = {
									name: prod.name,
									value: prod.code,
									tierEnabled: prod.tierEnabled,
								};
								options.push(obj);
							}
						});
					}
					options.sort(productComparator('name'));
					props.setProdOptions(options);
				});
			},
			loadTiers: params => {
				const prevSelectedTier =
					props.contract === undefined ? props.form.getFieldValue('tier') : props.contract.tier;
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_TIERS,
						params: {
							start: 0,
							length: 200,
							'columns[0][data]': 'product',
							'columns[0][search][value]': `(^${params}$)`,
							'columns[0][search][searchable]': true,
							'columns[0][search][regex]': true,
						},
					})
				).then(response => {
					const options = [];
					response.data.map(tier => {
						if (
							(tier.enable === 1 || prevSelectedTier === tier.tier) &&
							String(tier.contractType) === props.contractType
						) {
							const obj: SelectOpts = {
								name: tier.name,
								value: tier.tier,
								disable: tier.enable === 0,
							};
							options.push(obj);
						}
					});
					props.setTierOptions(options);
					props.setTiers(response.data);
				});
			},
			loadSerialLicenseNumberSuggestions: async () => {
				const products = ['GPR_SLICE', 'GPRSIM'];
				const requests = [];
				products.forEach(product => {
					const promise = dispatch(
						request({
							url: URL.GET_LICENSE_NUMBER_SUGGESTION,
							setLoading: props.setLoading,
							params: {
								...props.params,
								product,
								field: 'probeSN',
							},
						})
					);
					requests.push(promise);
				});

				const response = await Promise.all(requests);
				const serialLicenseNumberSuggestionHash: SerialLicenseNumberSuggestion = {};
				response.forEach(({ data }, index) => {
					serialLicenseNumberSuggestionHash[products[index]] = data;
				});
				props.setSerialLicenseNumberSuggestions(serialLicenseNumberSuggestionHash);
			},
		})
	),
	lifecycle({
		componentDidMount() {
			this.props.loadAvailableProducts();
			this.props.loadSerialLicenseNumberSuggestions();
			this.props.loadTiers(this.props.form.getFieldValue('product'));
			if (this.props.contract !== undefined) {
				this.props.loadGPRSliceLicenseById(this.props.contract.id);
			}
			this.props.setContractModified(false);
			this.props.setExpirationModified(false);
			this.props.setPrevContract(this.props.contract);
		},
		componentDidUpdate(prevProps) {
			const compare = (o1, o2) => {
				return o1 === o2 || (o1 === undefined && o2 === '') || (o1 === '' && o2 === undefined);
			};
			const formExpiration =
				this.props === undefined || this.props.form === undefined || this.props.form == null
					? undefined
					: this.props.form.getFieldValue('expirationDate');
			const prevExpiration =
				this.props === undefined || this.props.prevContract === undefined
					? undefined
					: this.props.prevContract.expirationDate;

			if (
				prevExpiration !== undefined &&
				formExpiration !== undefined &&
				formExpiration !== null &&
				!compare(prevExpiration, formExpiration.format('YYYY-MM-DD'))
			) {
				this.props.setExpirationModified(true);
			} else {
				this.props.setExpirationModified(false);
			}
			if (
				this.props !== undefined &&
				this.props.prevContract !== undefined &&
				this.props.contract !== undefined &&
				compare(this.props.prevContract.invoiceNo, this.props.form.getFieldValue('invoiceNo')) &&
				compare(this.props.prevContract.invoiceNo2, this.props.form.getFieldValue('invoiceNo2')) &&
				compare(this.props.prevContract.invoiceNo3, this.props.form.getFieldValue('invoiceNo3')) &&
				compare(this.props.prevContract.invoiceNo4, this.props.form.getFieldValue('invoiceNo4')) &&
				compare(this.props.prevContract.invoiceNo5, this.props.form.getFieldValue('invoiceNo5')) &&
				compare(this.props.prevContract.invoiceNo6, this.props.form.getFieldValue('invoiceNo6')) &&
				compare(this.props.prevContract.contactEmail, this.props.form.getFieldValue('contactEmail')) &&
				compare(this.props.prevContract.businessUnit, this.props.form.getFieldValue('businessUnit')) &&
				compare(this.props.prevContract.product, this.props.form.getFieldValue('product')) &&
				compare(this.props.prevContract.tier, this.props.form.getFieldValue('tier')) &&
				compare(this.props.prevContract.hardwareID, this.props.form.getFieldValue('hardwareID')) &&
				compare(this.props.prevContract.probeSN, this.props.form.getFieldValue('serialNo')) &&
				compare(this.props.prevContract.mASN, this.props.form.getFieldValue('mASerialNo')) &&
				compare(this.props.prevContract.language, this.props.form.getFieldValue('language')) &&
				compare(this.props.prevContract.countrySoldTo, this.props.form.getFieldValue('countrySoldTo')) &&
				compare(this.props.prevContract.bp, this.props.form.getFieldValue('bp')) &&
				compare(this.props.prevContract.remarks, this.props.form.getFieldValue('remarks')) &&
				compare(this.props.gprLicense.LicenseID, this.props.form.getFieldValue('gprLicenseId')) &&
				compare(this.props.gprLicense.LicensePassword, this.props.form.getFieldValue('gprLicensePassword')) &&
				(((formExpiration === null || formExpiration === undefined) &&
					(prevExpiration === null || prevExpiration === undefined || prevExpiration === '')) ||
					(formExpiration !== null &&
						this.props.form.getFieldValue('expirationDate') !== undefined &&
						compare(
							this.props.prevContract.expirationDate,
							this.props.form.getFieldValue('expirationDate').format('YYYY-MM-DD')
						))) &&
				(this.props.form.getFieldValue('isCareRedeemed')
					? this.props.prevContract.isCareRedeemed === 1
					: this.props.prevContract.isCareRedeemed !== 1) &&
				this.props.form.getFieldValue('isInternal') ===
					getName(UserTypeOptions, this.props.prevContract.isInternal)
			) {
				if (this.props.contractModified === true) {
					this.props.setContractModified(false);
				}
			} else if (this.props.contractModified === false) {
				this.props.setContractModified(true);
			}
			this.props.setUserType(this.props.form.getFieldValue('isInternal'));
			if (
				prevProps !== undefined &&
				this.props !== undefined &&
				this.props.form.getFieldValue('product') !== undefined
			) {
				if (
					(prevProps.currProd === '' && this.props.form.getFieldValue('product') !== '') ||
					prevProps.currProd !== this.props.form.getFieldValue('product')
				) {
					this.props.setCurrProd(this.props.form.getFieldValue('product'));
					this.props.loadTiers(this.props.form.getFieldValue('product'));
				} else if (
					this.props !== undefined &&
					this.props.tierOptions !== undefined &&
					this.props.contract !== undefined &&
					prevProps.contract !== undefined &&
					this.props.tierOptions.filter(to => to.value === this.props.contract.tier).length === 0 &&
					prevProps.currProd !== this.props.form.getFieldValue('product')
				) {
					this.props.loadTiers(this.props.form.getFieldValue('product'));
				}
			}
		},
	}),
	injectIntl
)(AddEditContract);

// @flow
import React from 'react';
import { groupBy, values, round, minBy, maxBy } from 'lodash';
import { compose, withProps, withHandlers, lifecycle, withState } from 'recompose';
import { /* withScriptjs, */ withGoogleMap, GoogleMap } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
// import { Spin } from 'antd';
import MarkerGroup from './MarkerGroup';
import styles from './RegionalAnalytics.styl';

const calculator = (points, styleCount) => {
	let text = 0;
	for (let i = 0; i < points.length; i++) {
		text += +points[i].label;
	}
	return {
		text,
		index: Math.min(styleCount, text.toString().length),
	};
};

export const GoogleMaps = ({
	users,
	data,
	onMapMounted,
	visible,
	setVisible,
}: {
	users: User[],
	data: RegionalAnalytics[],
	onMapMounted: Function,
	visible: ?string,
	setVisible: Function,
}) => {
	return (
		<GoogleMap
			defaultZoom={8}
			defaultCenter={
				data.length > 0
					? {
							lat: (minBy(data, 'lat').lat + maxBy(data, 'lat').lat) / 2,
							lng: (minBy(data, 'lon').lon + maxBy(data, 'lon').lon) / 2,
					  }
					: null
			}
			ref={onMapMounted}
		>
			<MarkerClusterer averageCenter enableRetinaIcons gridSize={60} maxZoom={21} calculator={calculator}>
				{values(groupBy(data, ({ lat, lon }) => round(lat, 4) + round(lon, 4))).map(points => (
					<MarkerGroup
						key={points[0].mid}
						points={points}
						users={users}
						visible={visible === points[0].mid}
						show={() => setVisible(points[0].mid)}
						hide={() => setVisible(null)}
					/>
				))}
			</MarkerClusterer>
		</GoogleMap>
	);
};

export default compose(
	// $FlowFixMe
	withHandlers(() => {
		let map;
		return {
			onMapMounted: () => ref => {
				map = ref;
			},
			onLoad: () => data => {
				map.fitBounds(
					new window.google.maps.LatLngBounds(
						new window.google.maps.LatLng(minBy(data, 'lat').lat, minBy(data, 'lon').lon),
						new window.google.maps.LatLng(maxBy(data, 'lat').lat, maxBy(data, 'lon').lon)
					)
				);
			},
		};
	}),
	withProps(() => ({
		// these are only needed for withScriptjs
		// googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${process.env.GOOGLE_MAPS_API_KEY || ''}`,
		// loadingElement: <Spin />,
		containerElement: <div className={styles.container} />,
		mapElement: <div className={styles.map} />,
	})),
	// withScriptjs,
	withGoogleMap,
	lifecycle({
		componentDidMount() {
			if (this.props.data.length > 0) {
				setTimeout(() => this.props.onLoad(this.props.data));
			}
		},
		componentWillReceiveProps(nextProps) {
			if (nextProps.data.length > 0) {
				nextProps.onLoad(nextProps.data);
			}
		},
		shouldComponentUpdate(nextProps) {
			// don't update if the data hasn't changed. we only need to re-render on data change anyway.
			// we use shallow comparison for this
			// eslint-disable-next-line eqeqeq
			if (this.props.data == nextProps.data) {
				return false;
			}

			return true;
		},
	}),
	withState('visible', 'setVisible', null)
)(GoogleMaps);

import { v4 as uuid } from 'uuid';

export const getDeviceID = () => {
	let deviceID = localStorage.getItem('deviceID');
	if (!deviceID) {
		deviceID = uuid();
		localStorage.setItem('deviceID', deviceID);
	}
	return deviceID;
};

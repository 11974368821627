// @flow
import React from 'react';
import classNames from 'classnames';
import { find } from 'lodash';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Layout, Modal, Button, Popconfirm, Icon, Tag, Popover } from 'antd';
import URL from 'apis/urls';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import { request } from 'actions/ajax';
import styles from './Tier.styl';
import tableStyles from '../shared/SmarterTable.styl';
import columnConfig from './columnConfig';
import TiersBaseTable from './TierBaseTable';
import AddEditTier from './AddEditTier';
import ExportTiers from './ExportTiers';
import { productComparator } from '../contracts/contractConsts';

export const Tiers = ({
	isSuper,
	isAdmin,
	tier,
	totalRecords,
	loadTiers,
	loading,
	setParams,
	selectedRowKeys,
	products,
	onRowSelection,
	editedTier,
	changeTier,
	deleteTiers,
	loadProducts,
	loadAllTierFeatures,
	features,
	prodOps,
}: {
	isSuper: boolean,
	isAdmin: boolean,
	tier: Tier[],
	totalRecords: number,
	loadTiers: Function,
	loading: boolean,
	setParams: Function,
	selectedRowKeys: string[],
	products: Product[],
	onRowSelection: Function,
	editedTier?: string,
	changeTier: Function,
	deleteTiers: Function,
	loadProducts: Function,
	loadAllTierFeatures: Function,
	features: Object,
	prodOps: { text: string, value: any }[],
}) => {
	const contractTypes = {
		'0': 'Device',
		'1': 'Maintenance Services',
		'2': 'Software',
	};

	return (
		<Layout>
			<Layout.Content>
				<Button className={tableStyles.button} type="primary" loading={loading} onClick={loadTiers}>
					<FormattedMessage id="Proceq.TableActionRefresh" />
				</Button>
				<Button className={tableStyles.button} type="primary" onClick={() => changeTier(0)}>
					<FormattedMessage id="Add Tier" />
				</Button>

				{isSuper && (
					<Popconfirm
						title={
							<FormattedMessage
								id="Do you want to delete tier ?"
								values={{ length: selectedRowKeys.length }}
							/>
						}
						onConfirm={deleteTiers}
						okText={<FormattedMessage id="Proceq.TextYes" />}
						cancelText={<FormattedMessage id="Proceq.TextNo" />}
					>
						<Button
							className={classNames(tableStyles.button)}
							type="danger"
							disabled={selectedRowKeys.length < 1 || selectedRowKeys.length > 5}
						>
							<FormattedMessage id="Delete Tier" />
						</Button>
					</Popconfirm>
				)}
				<ExportTiers />
				<TiersBaseTable
					rowKey="id"
					columns={[
						{
							title: 'Tier Name',
							dataIndex: 'name',
							filter: true,
							render: (text, record) => (
								<div>
									<span>{text}</span>
									&nbsp; &nbsp;
									<Button
										className={classNames(styles.edit_button)}
										onClick={() => changeTier(record.id)}
									>
										<Icon type="edit" />
									</Button>
									{(isAdmin || isSuper) && (
										<Popover
											placement="right"
											title={<span>{'Features'}</span>}
											content={
												<div className={styles.popover_content_root}>
													{features[record.product] !== undefined &&
														features[record.product][record.tier] !== undefined &&
														features[record.product][record.tier].map(function(feature) {
															return (
																<div
																	key={Math.random()
																		.toString(36)
																		.substring(7)}
																	className={styles.popover_content}
																>
																	<div
																		className={
																			feature.enable === 1
																				? styles.popover_description_code
																				: styles.popover_description_code_disable
																		}
																	>
																		{`[${feature.code}]`}
																	</div>
																	<div
																		className={
																			feature.enable === 1
																				? styles.popover_description_content
																				: styles.popover_description_content_disable
																		}
																	>
																		{feature.description}
																	</div>
																</div>
															);
														})}
												</div>
											}
											trigger="click"
										>
											<Button className={classNames(styles.edit_button)}>
												<Icon type="profile" />
											</Button>
										</Popover>
									)}
								</div>
							),
							sorter: true,
						},
						{
							title: 'Product',
							dataIndex: 'product',
							render: text => {
								let display;
								products.map(function(prod) {
									if (text === prod.code) {
										display = (
											<div>
												<span>{prod.name}</span>
											</div>
										);
									}
								});
								return display;
							},
							sorter: true,
							filterOps: prodOps,
							filter: true,
							multiSelect: true,
						},
						{
							title: 'Active / Inactive',
							dataIndex: 'enable',
							render: text => {
								if (text === 1) {
									return (
										<div>
											<Tag color="green">
												<FormattedMessage id="Active" />
											</Tag>
										</div>
									);
								}
								return (
									<div>
										<Tag color="volcano">
											<FormattedMessage id="Inactive" />
										</Tag>
									</div>
								);
							},
							sorter: true,
						},
						{
							title: 'Allow Expiration',
							dataIndex: 'allow_expiration',
							render: text => {
								if (text === 1) {
									return (
										<div>
											<Tag color="green">
												<FormattedMessage id="Yes" />
											</Tag>
										</div>
									);
								}
								return (
									<div>
										<Tag color="volcano">
											<FormattedMessage id="No" />
										</Tag>
									</div>
								);
							},
							sorter: true,
						},
						{
							title: 'Offer Eagle Care',
							dataIndex: 'offer_eagle_care',
							render: text => {
								if (text === 1) {
									return (
										<div>
											<Tag color="green">
												<FormattedMessage id="Yes" />
											</Tag>
										</div>
									);
								}
								return (
									<div>
										<Tag color="volcano">
											<FormattedMessage id="No" />
										</Tag>
									</div>
								);
							},
							sorter: true,
						},
						{
							title: 'Device ID',
							dataIndex: 'require_device_id',
							render: text => {
								if (text === 1) {
									return (
										<div>
											<Tag color="green">
												<FormattedMessage id="Yes" />
											</Tag>
										</div>
									);
								}
								return (
									<div>
										<Tag color="volcano">
											<FormattedMessage id="No" />
										</Tag>
									</div>
								);
							},
							sorter: true,
						},
						{
							title: 'Type',
							dataIndex: 'contract_type',
							render: (text, record) => {
								return (
									<div>
										<span>{contractTypes[record.contractType]}</span>
									</div>
								);
							},
							sorter: true,
							globalRegister: 'contracttypes',
							filter: true,
							multiSelect: true,
						},
						...columnConfig.COMMON,
					]}
					dataSource={tier}
					loadDataSource={loadTiers}
					loading={loading}
					setParams={setParams}
					selectedRowKeys={selectedRowKeys}
					onRowSelection={onRowSelection}
					maxRowSelection={5}
					totalRecords={totalRecords}
				/>
			</Layout.Content>
			<Modal
				visible={editedTier !== null}
				footer={null}
				onCancel={() => changeTier(null)}
				title={<FormattedMessage id={find(tier, { id: editedTier }) ? 'Edit Tier' : 'Add Tier'} />}
			>
				<AddEditTier
					tier={find(tier, { id: editedTier })}
					changeTier={changeTier}
					loadTiers={loadTiers}
					products={products}
					loadProducts={loadProducts}
					loadAllTierFeatures={loadAllTierFeatures}
				/>
			</Modal>
		</Layout>
	);
};

export default compose(
	withState('selectedRowKeys', 'onRowSelection', []),
	withState('editedTier', 'changeTier', null),
	withState('loading', 'setLoading', true),
	withState('tier', 'setTiers', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams'),
	withState('products', 'setProducts', []),
	withState('features', 'setFeatures', {}),
	withState('prodOps', 'setProdOps', []),
	connect(
		state => ({
			isSuper: hasRoleLevel(state.session.scopes, ROLES.SUPER),
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
		}),
		(dispatch: Function, props) => ({
			loadTiers: () => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_TIERS,
						setLoading: props.setLoading,
						params: {
							...props.params,
							start: 0,
							length: 200,
						},
					})
				).then(data => {
					props.setTiers(
						data.data.map(t => {
							// change key allowExpiration to allow_expiration
							t.allow_expiration = t.allowExpiration;
							// change key offerEagleCare to offer_eagle_care
							t.offer_eagle_care = t.offerEagleCare;
							// change key requireDeviceID to require_device_id
							t.require_device_id = t.requireDeviceID;
							return t;
						})
					);
					props.setTotalRecords(data.recordsFiltered);
				});
			},
			loadAllTierFeatures: () => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_ALL_TIER_FEATURES,
						setLoading: props.setLoading,
						params: {
							...props.params,
						},
					})
				).then(data => {
					props.setFeatures(data);
				});
			},
		})
	),
	connect(null, (dispatch: Function, props) => ({
		deleteTiers: () => {
			dispatch(
				request({
					method: 'DELETE',
					url: URL.DELETE_TIER,
					setLoading: props.setLoading,
					params: {
						product: props.tier.filter(t => props.selectedRowKeys.includes(t.id))[0].product,
						ids: props.selectedRowKeys,
					},
				})
			).then(() => {
				props.loadTiers();
				props.onRowSelection([]);
			});
		},
	})),
	connect(null, (dispatch: Function, props) => ({
		loadProducts: () => {
			dispatch(
				request({
					method: 'GET',
					url: URL.GET_PRODUCT,
					setLoading: props.setLoading,
					params: {},
				})
			).then(data => {
				const options = data
					.map(p => {
						return { value: p.code, text: p.name };
					})
					.filter(p => p.value !== undefined && p.value !== '');
				options.sort(productComparator('text'));
				props.setProdOps(options);
				props.setProducts(data);
			});
		},
	})),
	lifecycle({
		componentDidMount() {
			this.props.setParams({
				start: 0,
				length: 20,
			});
			setTimeout(() => {
				this.props.loadTiers();
				this.props.loadProducts();
				this.props.loadAllTierFeatures();
			});
		},
	})
)(Tiers);

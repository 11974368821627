// @flow
import { debounce } from 'lodash';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Layout, Button } from 'antd';
import URL from 'apis/urls';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import { getTextValue } from 'utils/registryHelper';
import { request } from 'actions/ajax';
import { downloadLicensesPdf } from './AddEditSaasContract';
import tableStyles from '../shared/SmarterTable.styl';
import columnConfig from './columnConfig';
import { generateParams, getDefaultProductFilters } from './Contracts';
import type { ColumnConfigProps } from './columnConfig';
import { productComparator, caseInsensitiveCompare, AuditHistoryType } from './contractConsts';
import { generateFilterQuery } from '../shared/SmarterTable';
import ContractsBaseTable from './ContractsBaseTable';
import Legend from './Legend';

import type { ContractTypes } from './contractConsts';
import ExportSaaSLicenses from './ExportSaaSLicenses';
import AuditHistory from './AuditHistory';
import colors from '../../styles/colors.json';
import { DATE_SERVER_FORMAT, highlightExpiringContractsOrLicenses } from '../../utils/dateHandler';

const SaasProds = getTextValue(['saasProducts']).map(({ value }) => value);

export const Licenses = ({
	contracts,
	totalRecords,
	loadLicenses,
	downloadLicenses,
	loading,
	setParams,
	selectedRowKeys,
	onRowSelection,
	productFamily,
	products,
	loadTiers,
	contractType,
	usersMap,
	tierOps,
	tierFilterOps,
	usersSearch,
	setUsersSearch,
	loadUsers,
	usersLoading,
}: {
	contracts: Contract[],
	totalRecords: number,
	loadLicenses: Function,
	downloadLicenses: Function,
	loading: boolean,
	setParams: Function,
	selectedRowKeys: string[],
	onRowSelection: Function,
	productFamily: ProductFamily,
	products: Product[],
	loadTiers: Function,
	contractType: ContractTypes,
	usersMap: Object,
	tierOps: Object,
	tierFilterOps: { text: string, value: any }[],
	setUsersSearch: Function,
	usersSearch: User[],
	loadUsers: Function,
	usersLoading: boolean,
}) => {
	const userOption = ({ id, firstName, lastName, email }) => {
		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<span style={{ alignSelf: 'flex-start', flex: 1 }}>
					{`(uID: ${id}) ${firstName || ''} ${lastName || ''}`}
				</span>
				<span>{`(${String(email)})`}</span>
			</div>
		);
	};

	const columnConfigType = 'SAAS_LICENSES';

	const customizedColumns: { [key: string]: ColumnConfigProps } = {
		activeUID: {
			title: 'Active User',
			dataIndex: 'activeUID',
			render: (text: number) => {
				const res = usersMap !== undefined && usersMap[text] !== undefined ? usersMap[text] : '';
				return (
					<div>
						<span>{res}</span>
					</div>
				);
			},
			filter: true,
			filterSearch: {
				onSearch: loadUsers,
				onBlur: () => setUsersSearch([]),
				searchLoading: usersLoading,
				options: usersSearch,
				customOption: userOption,
			},
		},
		product: {
			title: 'App.Product',
			dataIndex: 'product',
			globalRegister: 'saasProducts',
			filter: true,
			multiSelect: true,
			render: (text, record) => {
				const selected = products.filter(p => {
					return caseInsensitiveCompare(p.code, record.product);
				});
				text = selected.length > 0 ? selected[0].name : text;
				return (
					<div>
						<span>{text}</span>
					</div>
				);
			},
		},
		uID: {
			title: 'Generated By',
			dataIndex: 'uID',
			render: (text: number) => {
				const res = usersMap !== undefined && usersMap[text] !== undefined ? usersMap[text] : '';
				return (
					<div>
						<span>{res}</span>
					</div>
				);
			},
			filter: true,
			filterSearch: {
				onSearch: loadUsers,
				onBlur: () => setUsersSearch([]),
				searchLoading: usersLoading,
				options: usersSearch,
				customOption: userOption,
			},
		},
		tier: {
			title: 'Type',
			dataIndex: 'tier',
			filter: true,
			sorter: true,
			multiSelect: true,
			filterOps: tierFilterOps,
			render: (text: String) => {
				return (
					<div>
						<span>{tierOps[text]}</span>
					</div>
				);
			},
		},
		contractLink: {
			title: 'Contract',
			dataIndex: 'contractID',
			render: id => {
				const CONTRACT_LINK = `${window.location.origin}/contracts?contractId=${id}`;
				return (
					<a href={CONTRACT_LINK} target="_blank" rel="noreferrer">
						{id}
					</a>
				);
			},
			filter: true,
		},
		history: {
			title: 'History',
			dataIndex: '',
			render: record => {
				return <AuditHistory id={record.id} documentType={AuditHistoryType.License} isProductSaas={false} />;
			},
		},
	};

	const columns = columnConfig[columnConfigType].map((column: Object) => {
		return customizedColumns[column.dataIndex] || column;
	});

	const getExpirationDate = record => moment(record.expirationDate, DATE_SERVER_FORMAT);

	return (
		<Layout>
			<Layout.Content>
				<Legend legendTextId={'App.LicensesSixtyDaysOrExpiredLegend'} />
				<Legend legendColor={colors.light_green} legendTextId={'App.LicensesSixtyOneOrNinetyDaysLegend'} />
				<Legend legendColor={'lightgray'} legendTextId={'App.RevokedLegend'} />
				<Button className={tableStyles.button} type="primary" loading={loading} onClick={loadLicenses}>
					<FormattedMessage id="Proceq.TableActionRefresh" />
				</Button>
				<ExportSaaSLicenses />
				<Button
					className={tableStyles.button}
					type="primary"
					loading={loading}
					onClick={() => downloadLicenses(selectedRowKeys)}
					disabled={selectedRowKeys.length < 1}
				>
					<FormattedMessage id="Download Activation PDF" />
				</Button>
				<ContractsBaseTable
					rowKey="id"
					columns={columns}
					onRow={record => highlightExpiringContractsOrLicenses(record, getExpirationDate(record), false)}
					dataSource={contracts}
					loadDataSource={loadLicenses}
					loading={loading}
					setParams={setParams}
					selectedRowKeys={selectedRowKeys}
					onRowSelection={onRowSelection}
					maxRowSelection={5}
					totalRecords={totalRecords}
					loadTiers={loadTiers}
					defaultFilters={{
						product: getDefaultProductFilters(contractType, productFamily),
						type: [contractType],
					}}
				/>
			</Layout.Content>
		</Layout>
	);
};

export default compose(
	withState('selectedRowKeys', 'onRowSelection', []),
	withState('editedContract', 'changeContract', null),
	withState('loading', 'setLoading', true),
	withState('contracts', 'setContracts', []),
	withState('totalRecords', 'setTotalRecords', 0),
	withState('params', 'setParams'),
	withState('products', 'setProducts', []),
	withState('tiers', 'setTiers', []),
	withState('features', 'setFeatures', {}),
	withState('tierOps', 'setTierOps', {}),
	withState('tierFilterOps', 'setFilterTierOps', {}),
	withState('prodOps', 'setProdOps', []),
	withState('usersMap', 'setUsersMap', {}),
	withState('usersSearch', 'setUsersSearch', []),
	withState('usersLoading', 'setUsersLoading', false),
	connect(
		state => ({
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
		}),
		(dispatch: Function, props) => ({
			loadLicenses: () => {
				dispatch(
					request({
						url: URL.GET_LICENSES,
						setLoading: props.setLoading,
						params: {
							...props.params,
							product: undefined,
						},
					})
				).then(data => {
					let serialCounter = props.params.start;
					const contractList = data.data.map(entry => {
						serialCounter++;
						return { serialNo: serialCounter, ...entry };
					});
					props.setContracts(contractList);
					props.setTotalRecords(data.recordsFiltered);

					// fetch users map
					const uIDs = [];
					data.data.forEach(license => {
						if (license.uID) uIDs.push(license.uID);
						if (license.activeUID) uIDs.push(license.activeUID);
					});
					const uniqueIds = [...new Set(uIDs)];
					if (uniqueIds.length > 0) {
						dispatch(
							request({
								method: 'POST',
								url: URL.GET_USERS,
								params: {
									ids: uniqueIds,
								},
							})
						).then(user => {
							const result = {};
							for (const key in user.data) {
								result[user.data[key].id] = user.data[key].email;
							}
							props.setUsersMap(result);
						});
					}
				});
			},
			loadProducts: (callback: Function) => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_PRODUCT,
						setLoading: props.setLoading,
						params: {
							connectionType: props.connectionType,
						},
					})
				).then(data => {
					const options = data
						.map(product => {
							if (SaasProds.includes(product.code)) {
								return { value: product.code, text: product.name };
							}
							return null;
						})
						.filter(Boolean);
					options.sort(productComparator('text'));
					props.setProdOps(options);
					props.setProducts(data, () => {
						if (typeof callback === 'function') {
							callback();
						}
					});
				});
			},
			downloadLicenses: selectedRowKeys => {
				const params = {
					iDs: selectedRowKeys,
				};
				downloadLicensesPdf(dispatch, props, params, null);
			},
			loadUsers: debounce((value: string) => {
				if (value.trim().length < 1) {
					return;
				}
				props.setUsersLoading(true);
				dispatch(
					request({
						method: 'POST',
						url: URL.GET_USERS,
						params: {
							q: value.trim(),
						},
					})
				).then(data => {
					const userList = data.data;
					userList.sort((u1, u2) => {
						return u1.email === u2.email ? 0 : u1.email > u2.email ? 1 : -1;
					});
					props.setUsersSearch(userList);
					props.setUsersLoading(false);
				});
			}, 500),
			loadTiers: () => {
				dispatch(
					request({
						method: 'GET',
						url: URL.GET_TIERS,
						setLoading: props.setLoading,
						params: {
							start: 0,
							length: 200,
						},
					})
				).then(data => {
					const tempMap = {};
					let tierOptions = [];
					if (data.data !== undefined && data.data.length > 0) {
						data.data.forEach(t => {
							tempMap[t.tier] = t.name;
						});
						tierOptions = data.data
							.map(t => {
								if (
									props.contractType === undefined ||
									props.contractType === '' ||
									t.contractType === parseInt(props.contractType, 10)
								) {
									return { value: t.tier, text: t.name };
								}
								return { value: '', text: '' };
							})
							.filter(t => t.value !== undefined && t.value !== '');
					}
					props.setFilterTierOps(tierOptions);
					props.setTierOps(tempMap);
					props.setTiers(data.data);
				});
			},
		})
	),
	lifecycle({
		componentDidMount() {
			this.props.setParams({
				start: 0,
				length: 20,
				...generateParams(this.props.productFamily, this.props.contractType),
				...generateFilterQuery('type', this.props.contractType, 1),
			});
			setTimeout(() => {
				const self = this;
				this.props.loadProducts(() => {
					self.props.loadLicenses();
					self.props.loadTiers();
				});
			}, 2000);
		},
	})
)(Licenses);

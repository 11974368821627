// @flow
import React from 'react';
import classNames from 'classnames';
import { get, debounce } from 'lodash';
import { connect } from 'react-redux';
import { compose, withState, withProps, withHandlers } from 'recompose';
import { Select, Form, Row, Tabs, Button, Checkbox, Radio, message } from 'antd';
import axios from 'axios';
import type { FormProps } from 'antd/lib/form';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectIntlProvidedProps } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import { getInitialRange } from 'constants/dateRange';
import { hasRoleLevel, ROLES } from 'utils/rolesHelper';
import DateRange from '../shared/DateRange';
import SmarterSelect from '../shared/SmarterFormItems/Select';
// import Equotip from '../dashboardnew/Equotip';
// import Schmidt from '../dashboardnew/Schmidt';
// import Storage from '../dashboardnew/Storage';
import MapView from './MapView';
import styles from './UserAnalytics.styl';
import regionalStyles from './RegionalAnalytics.styl';
import layoutStyles from '../layout/Layout.styl';

export const UserAnalytics = ({
	loading,
	product,
	isAdmin,
	users,
	locations,
	refresh,
	tab,
	setTab,
	form,
	intl,
	cancel,
	loadUsers,
	usersLoading,
	setUsers,
}: {
	loading: boolean,
	product: ProductCode,
	productModel: {},
	isAdmin: boolean,
	users: User[],
	analytics: {
		common: CommonAnalytics,
		measurements: Object,
		storage: StorageAnalytics,
	},
	locations: {
		data: RegionalAnalytics[],
		measurementCount: number,
	},
	refresh: Function,
	tab: string,
	setTab: Function,
	form: FormProps,
	intl: Object,
	cancel: Function,
	loadUsers: Function,
	usersLoading: boolean,
	setUsers: Function,
} & InjectIntlProvidedProps) => (
	<div className="container">
		<div className={classNames(regionalStyles.header_container, layoutStyles.container)}>
			<h2 className="label-statistics">
				<FormattedMessage id="App.DashboardFilters" />
			</h2>
			<Form onSubmit={refresh(tab)}>
				<div className={regionalStyles.left_filter_section}>
					<Form.Item label={<FormattedMessage id="App.DashboardUserFilter" />}>
						{form.getFieldDecorator('userID', {
							rules: [{ required: true }],
						})(
							<Select
								className={styles.select}
								dropdownMatchSelectWidth={false}
								showSearch
								notFoundContent={
									usersLoading
										? intl.formatMessage({ id: 'App.AnalyticsSearching' })
										: intl.formatMessage({ id: 'App.AnalyticsNoResults' })
								}
								onBlur={() => setUsers([])}
								onSearch={value => loadUsers(value, isAdmin)}
								filterOption={false}
								placeholder={intl.formatMessage({
									id: isAdmin
										? 'App.DashboardUserFilterPlaceholderAdmin'
										: 'App.DashboardUserFilterPlaceholder',
								})}
							>
								{users.map(option => (
									<Select.Option key={option.id} option={option}>
										{isAdmin ? (
											<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
												<span style={{ alignSelf: 'flex-start', flex: 1 }}>
													{`(uID: ${option.id}) ${option.firstName} ${option.lastName}`}
												</span>
												<span>{`(${String(option.username)})`}</span>
											</div>
										) : (
											<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
												<span style={{ alignSelf: 'flex-start', flex: 1 }}>
													{`uID: ${option.id}`}
												</span>
												<span>{`(${String(option.email)})`}</span>
											</div>
										)}
									</Select.Option>
								))}
							</Select>
						)}
					</Form.Item>
					<Form.Item label={<FormattedMessage id="App.AnalyticsFilter" />}>
						{form.getFieldDecorator('filter', {
							initialValue: 'family',
						})(
							<Radio.Group>
								<Radio value={'family'}>{'App'}</Radio>
								<Radio value={'productModel'}>{'Product'}</Radio>
							</Radio.Group>
						)}
					</Form.Item>
					{form.getFieldValue('filter') === 'family' && (
						<Form.Item label={<FormattedMessage id="App.Product.Family" />}>
							{form.getFieldDecorator('product', {
								initialValue: product,
							})(<SmarterSelect className={styles.select} globalRegister="availproductsappfull" />)}
						</Form.Item>
					)}
					{form.getFieldValue('filter') === 'productModel' && (
						<Form.Item label={<FormattedMessage id="App.ProductModel" />}>
							{form.getFieldDecorator('productModel', {
								initialValue: 'LEEBD',
							})(<SmarterSelect className={styles.select} globalRegister="productModels" />)}
						</Form.Item>
					)}
				</div>
				<div className={regionalStyles.right_filter_section}>
					<Form.Item label={<FormattedMessage id="App.DashboardDateRange" />}>
						{form.getFieldDecorator('dates', {
							initialValue: getInitialRange(),
						})(<DateRange />)}
					</Form.Item>
				</div>
				<Row>
					<Form.Item className={classNames(styles.second_row, styles.left)}>
						{form.getFieldDecorator('isCreatedOnly', {
							valuePropName: 'checked',
						})(
							<Checkbox>
								<FormattedMessage id="App.AnalyticsCreatedOnly" />
							</Checkbox>
						)}
					</Form.Item>
				</Row>
				<Row>
					{loading && (
						<Button type="default" onClick={cancel} className={styles.cancel_button}>
							<FormattedMessage id="App.AnalyticsRegionExportCancel" />
						</Button>
					)}
					<Button
						type="primary"
						htmlType="submit"
						loading={loading}
						className={classNames(styles.second_row, styles.right)}
					>
						<FormattedMessage id="Proceq.TableActionRefresh" />
					</Button>
				</Row>
			</Form>
		</div>
		<Tabs
			activeKey={tab}
			className={classNames(layoutStyles.container, regionalStyles.user)}
			style={{ padding: 20 }}
			onChange={key => {
				setTab(key);
				refresh(key)();
			}}
		>
			{/* <Tabs.TabPane tab="Statistics" key="1">
				<Spin spinning={loading}>
					{has(analytics, 'storage') && <Storage {...analytics} />}
					{form.getFieldValue('product') === 'EQUOTIP' && has(analytics, 'measurements.nImpacts') && (
						<Equotip isAdmin {...analytics} />
					)}
					{form.getFieldValue('product') === 'SCHMIDT' && has(analytics, 'measurements.nImpacts') && (
						<Schmidt isAdmin {...analytics} />
					)}
				</Spin>
			</Tabs.TabPane> */}
			<Tabs.TabPane tab="Map View" key="1">
				<MapView
					loading={loading}
					data={get(locations, 'data', [])}
					measurementCount={get(locations, 'measurementCount', 0)}
				/>
			</Tabs.TabPane>
		</Tabs>
	</div>
);

export default compose(
	injectIntl,
	Form.create({}),
	withState('loading', 'setLoading', false),
	withState('usersLoading', 'setUsersLoading', false),
	withState('users', 'setUsers', []),
	withState('analytics', 'setAnalytics', {}),
	withState('locations', 'setLocations', []),
	withState('tab', 'setTab', '1'),
	withState('cancelSource', 'setCancelSource', axios.CancelToken.source()),
	withHandlers({
		cancel: ({ cancelSource, intl, setCancelSource, setLoading }) => () => {
			cancelSource.cancel();
			message.error(intl.formatMessage({ id: 'App.AnalyticsOperationCancelled' }));
			setCancelSource(axios.CancelToken.source());
			setLoading(false);
		},
	}),
	connect(
		state => ({
			product: state.session.productType,
			isAdmin: hasRoleLevel(state.session.scopes, ROLES.ADMIN),
		}),
		(dispatch: Function, props) => ({
			loadUsers: debounce((value: string, isAdmin: boolean) => {
				if (value.length < 1) {
					return;
				}
				props.setUsersLoading(true);
				dispatch(
					request({
						method: 'POST',
						url: isAdmin ? URL.GET_ACCOUNTS : URL.GET_USERS,
						params: {
							q: value,
						},
					})
				).then(data => {
					props.setUsers(data.data);
					props.setUsersLoading(false);
				});
			}, 500),
			loadAnalytics: values => {
				const params = {
					product: values.product,
					userID: +values.userID,
					productModel: values.productModel,
					from: values.dates.from ? values.dates.from.unix() : undefined,
					to: values.dates.to ? values.dates.to.unix() : undefined,
				};
				props.setAnalytics({});
				dispatch(
					request({
						method: 'POST',
						url: URL.USER_ANALYTICS,
						setLoading: props.setLoading,
						params: {
							...params,

							metrics: ['common', 'measurements', 'storage'],
							flags: ['alive', 'trashed', 'deleted'],
						},
						options: {
							cancelToken: props.cancelSource.token,
						},
					})
				)
					.then(data => {
						props.setAnalytics(data);
						props.setLoading(false);
					})
					.catch(() => {
						props.setLoading(false);
						props.cancelSource.cancel();
						props.setCancelSource(axios.CancelToken.source());
					});
			},
			loadLocationAnalytics: values => {
				const params = {
					isCreatedOnly: values.isCreatedOnly,
					product: values.product,
					productModel: values.productModel,
					userID: +values.userID,
					from: values.dates.from ? values.dates.from.unix() : undefined,
					to: values.dates.to ? values.dates.to.unix() : undefined,
					metrics: ['geo'],
				};

				props.setLocations({});
				dispatch(
					request({
						method: 'POST',
						url: URL.USER_ANALYTICS_LOCATION,
						setLoading: props.setLoading,
						params,
						options: {
							cancelToken: props.cancelSource.token,
						},
					})
				).then(data => {
					const product = values.product || values.productModel;
					const aggregatedData = [];
					get(data, 'data', []).forEach(d => {
						aggregatedData.push({ ...d, product });
					});
					data.data = aggregatedData;
					props.setLocations(data);
					props.setLoading(false);
				});
			},
		})
	),
	withProps(props => ({
		refresh: () => e => {
			if (e) e.preventDefault();
			props.setLoading(true);
			props.form.validateFields((err, values) => {
				if (err) {
					props.setLoading(false);
					return;
				}

				/* if (tab === '1') {
					props.loadAnalytics(values);
				} else {
					props.loadLocationAnalytics(values);
				} */
				props.loadLocationAnalytics(values);
			});
		},
	}))
)(UserAnalytics);
